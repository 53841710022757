<template>
  <div class="app-index">
    <div class="fix-background">
      <div class="top-background"></div>
    </div>
    <div class="main-wrap pt-embedded-in-ios" id="page-height-reference">
      <div class="logo hide-in-embedded-ios">
        <img src="../../assets/images/common/logo.png" alt="logo">
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import setViewportHeight from '@utils/mixins/set-viewport-height.js'
import desktopUiFixes from '@utils/mixins/desktop-no-common-parts-ui-fixes.js'

export default {
  mixins: [setViewportHeight, desktopUiFixes],
}
</script>
<style lang="scss" scoped>
.fix-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  .top-background {
    height: 30vh;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background-color: #F0EEF8;
  }
}

.main-wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  left: 0;
  top: 0;
  .logo {
    padding: 22px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 63px;
    }
  }
}
</style>
